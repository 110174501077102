*{
  font-family: 'Poppins', sans-serif !important;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  }
  .lds-ripple div {
  position: absolute;
  border: 4px solid #000;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
  0% {
  top: 36px;
  left: 36px;
  width: 0;
  height: 0;
  opacity: 1;
  }
  100% {
  top: 0px;
  left: 0px;
  width: 72px;
  height: 72px;
  opacity: 0;
  }
  }
.fullpage-loader{
  width: 100%;
  height:100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 11111111;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: rgba(255,255,255,0.8);
  /* display: none; */
  }

@media (max-width: 992px){
  .information-header{
    flex-wrap: wrap;
  }
  .ih-result-div{
    width: 100%;
    margin-top: 10px;
    padding-bottom: 10px;
  }
}
@media (max-width: 767px){
  
  
  .phychological-skills-div{
    width: 50% !important;
    left: 48%;
  }
  .mandatory-skills-wrapper, .opyional-skills-wrapper,.soft-skill-wrapper,.psycological-skill-wrapper, .final-remark-wrapper{
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
}
@media (max-width: 575px){
  
  .feedback-rating{
    width: 200px !important;
  }
  .feedback-rating-ring{
    width: 100px !important
  }
  .overall-rating-center{
    width: 100px !important;
    height: 100px !important;
  }
  .suspected-image{
    width: 120px;
  }
  .ih-result-div{
    justify-content: space-between;
    padding-left: 20px;
  }
  p{
    font-size: 14px !important;
  }
  .psycological-skill-wrapper {
    /* margin:0 !important; */
    width: 94% !important;
  }
  .psycological-skill-content{
    width: 94% !important;
  }
  .candidate-skills{
    width: 350px !important;
  }
  .screenshot-wrapper{
    padding:20px; 
  }
  .individual-graph-wrapper{
    flex-basis: 100% !important;
  }
}