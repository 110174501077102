.circular-graph-wrapper {
    padding-top: 40px;
    background-color: #f5f5f5;

    padding-bottom: 20px;
}

.graph-img {
    width: 110px;
    /* height: 60%; */
}

@media only screen and (max-width: 600px) {
    /* .graph-img {
        width: 80%;
        height: 45%;
    } */
}

.font-style-header-circular {

    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 44px;
    letter-spacing: 0.04em;
    color: #FFFFFF;
}

@media only screen and (max-width: 1200px) {
    .font-style-header-circular {
        font-size: 3.5vw;
    }
}

@media only screen and (max-width: 900px) {
    .font-style-header-circular {
        font-size: 4vw;
    }
}

@media only screen and (max-width: 600px) {
    .font-style-header-circular {
        font-size: 5vw;
    }
/* 
    .graph-img {
        width: 80%;
        height: 45%;
    } */
}

.circular-graph-header {
    clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 100% 100%, 0% 100%);

    padding: 10px 10px 10px 7%;
    width: 40%;
    background: #133F7D;
}


@media only screen and (max-width: 600px) {
    .circular-graph-header {
        width: 95%;
        padding: 10px;
        clip-path: none;
        justify-content: center;
        display: flex;
    }
}

.mandatory-skills-graph-wrapper {
    margin: 24px 2%;
    padding-top: 32px;
    padding-bottom: 28px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 76px;
}

.d-flex {
    display: flex;
    justify-content: space-around;
}
.flex-wrap{
    flex-wrap: wrap;
}

.row1 {
    /* padding: 20px 80px; */

    padding: 2% 5%;
}

.row2 {
    /* padding: 23px 180px; */
    padding: 23px 17%;
}

@media only screen and (max-width: 600px) {
    .row2 {
        padding: 2% 5%;
    }
}

.row3 {
    display: none;
    padding: 2% 5%;
}

@media only screen and (max-width: 600px) {
    .row3 {
        display: block;
    }
}

.row2-img {
    width: 100%;
    height: 60%;
}

@media only screen and (max-width: 600px) {
    .row2-img {
        width: 80%;
        height: 56%;
    }
}

.row3-img {
    width: 100%;
    height: 60%;
}

.individual-graph-wrapper {
    text-align: center;
    margin-top: 20px;
    flex-basis: 25%;
}

@media only screen and (max-width: 600px) {
    .last-pic {
        display: none;
    }

    .row3-img {
        width: 26%;
    }
}

.graph-title {

    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 33px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.04em;

    color: #000000;
}

.graph-descp-percent {

    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 33px;
    /* identical to box height */

    letter-spacing: 0.04em;

    color: #133F7D;
}

.graph-descp-comment {

    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 33px;
    letter-spacing: 0.04em;
}

.cg {
    color: #3A992B;
}

.cr {
    color: #C55D56;
}

.cy {
    color: #B7BB0B;
}

.optional-skills-wrapper {
    margin-top: 58px;
}

/* .optional-graph-header {
    /* padding: 107px 10px 10px 10px; */