.App {
  /* text-align: center; */
  /* padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; */
  margin: 0 auto;
  width: 100%;
  background-color: #F6F7FB;
  /* height: 1300px; */
}

@media (min-width: 1400px) {
  .App {
    width: 1350px !important;
    /* background-color: green; */
  }
}


.pagebreak-after {
  page-break-after: always;

}

.pagebreak {
  page-break-before: always;
}

