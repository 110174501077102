* {
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
}

.information-header {
    width: 100%;
    background-color: #133F7D;
    /* height: 200px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ih-div {
    padding: 0 20px;
}

.ih-img {
    width: 200px;
    min-height: 220px;
    background-color: #D7EEFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.candidate-avatar {
    width: 160px;
    height: 160px;
    border-radius: 50px;
}

.candidate-avatar img {
    border-radius: 100px;
}

.ih-basic-info {
    padding: 20px;
    /* height: 200px; */
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: white;
    padding-left: 60px;
}

.ih-basic-info span {
    font-weight: 200;
    font-size: 12px;
}

.ih-basic-info p {
    margin-top: 0;
    margin-bottom: 10px;
}

.suspected-image {
    /* width: 150px; */
    /* padding-right: 50px; */
    margin-top: 15px;
}

.feedback-rating {
    width: 300px;
}

.ih-result-div {
    display: flex;
    align-items: center;
    /* justify-content: flex-start; */
    flex-direction: row-reverse;
    /* min-height: 190px; */
}

.pagebreak-after {
    page-break-after: always;
}

.pagebreak {
    page-break-before: always;
}

.img-logo {
    /* height: 114px; */
    width: 150px;
}

.img-div {
    /* background-color: white; */
    width: 50%;
    /* clip-path: polygon(0% 100%, 81% 100%, 110% 0%, 0% 0%); */
    text-align: left;
}

.header-content-wrapper {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: flex-end;
}

.blank-div {
    height: 72px;
    width: 100%;
    background-color: white;
}

.font-style-header {
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 2.8vw;
    line-height: 44px;
    font-style: normal;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    background-color: #133F7D;
}

.font-style {
    font-family: 'Manrope';
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    letter-spacing: 0.04em;
    color: black
}

.content-div {
    height: 24%;
    width: 100%;
    background-color: #133F7D;
    padding: 10px 10px 10px 9%;
    clip-path: polygon(100% 100%, 0% 100%, 10% 0%, 100% 0%);
}

.content-div-span {
    margin-left: 15%;
    font-size: 1.5rem;
}

.btn {
    cursor: pointer;
}

.btn-info {
    background-color: #11B2B8;
    color: white;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    border: 1px solid;
    border-radius: 7px;
    border-color: #11B2B8;
    padding: 5px 15px;
}

.btn-g {
    background-color: #3A992B;
    color: white;
    font-family: Manrope;
    font-size: 15px;
    text-align: center;
    height: 35px;
    width: 78px;
    margin-right: 106px;
    margin-top: 22px;
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    border: 1px solid;
    border-radius: 7px;
    border-color: #3A992B;

}

.toplayer-ellip-img {
    width: 105px;
}

.report-wrapper {
    background-color: #F5F5F5;
}

.section {
    display: flex;
    position: relative;
    justify-content: space-between;
}

.tech-details-wrapper {
    display: flex;
    width: 50%;
}

.tech-details {
    width: 50%;
    position: absolute;
    top: 36%;
    left: 2%;
}

.profile {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 30px;
}

.font-syle-profile {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
}

.font-style-profile-span {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    color: #626262;
}

.profile-details {
    position: absolute;
    top: 77%;
    left: 21%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
}

.profile-details-p1 {
    color: #626262;
}

.profile-details-p2 {
    color: #133F7D;
    font-weight: 1000;
    font-size: 16px;
}

.profile-details-p3 {
    color: #000000;
    font-weight: 600;
}

.proxy {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    margin-top: 32px;
}

.proxy-chart-wrapper {
    width: 100%;
    background: #E7EBFB;
    border-top-right-radius: 76px;
    border-bottom-right-radius: 76px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 32px;
    padding-bottom: 20px;
}

.proxy-rating-span {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    color: #000000;
}

.proxy-title-wrapper {
    background: #E7EBFB;
    width: 100%;
    display: flex;
    margin-top: 20px;
    justify-content: center;
}

.proxy-title {
    padding: 10px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 2.5vw;
    line-height: 33px;
    text-align: center;
    color: #EC2D38;
}

.arrow-div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 25px;
    margin-top: 27px;
}

.arrow-img {
    position: relative;
    right: 25%;
    width: 40px;
}

.recommendation {
    display: flex;
    margin-top: 5px;
}

.recommendation-span {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.04em;
    display: table-cell;
    vertical-align: middle;
    color: #FFFFFF;
}

.recommendation-div {
    width: 27%;
    height: 46px;
    display: table;
    border-radius: 13px;
    position: absolute;
    bottom: 50;
}

.red {
    background-color: #E96F68;
    left: 10%;
    z-index: 2;
}

.yellow {
    left: 35%;
    background: #F5E659;
    z-index: 1;
}

.green {
    margin-left: 59%;
    z-index: 0;
    background: #61BE52;
}

.candidate-overall-skills {
    display: flex;
    margin-top: 50px;
}

.candidate-skills-wrapper {
    padding-top: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column !important;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 76px 0px 0px 76px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.candidate-skills {
    margin: auto;
    width: 400px;
    margin-top: 40px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.candidate-skills-heading {
    display: flex;
    justify-content: center;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    line-height: 44px;
    letter-spacing: 0.04em;
    color: #133F7D;
}

.overAll-skills-header {
    width: 100%;
    /* clip-path: polygon(0 2%, 19% 197%, 353% 0%, 0% 0%); */
    position: relative;
    /* right: 6%; */
    background-color: #133F7D;
    padding-bottom: 10px;
    justify-content: flex-end;
    display: flex;
    padding-top: 10px;
}

.overAll-skills {
    width: 50%;
    /* margin-top: 30px !important; */
}

.overAll-skills-span {
    margin-right: 10%;
    font-size: 1.5rem;
}

.toplayer-ellip-div {
    padding-top: 25px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.toplayer-ellip-title {
    margin: 2.5px 5px;
    font-weight: 600;
}

.toplayer-ellip-percent {
    margin: 2.5px 5px;
    font-weight: 600;
    color: #133F7D;
}

.font-style-percent {
    font-family: Manrope;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
}

.vector-wrapper {
    display: flex;
    position: relative;
    bottom: 22px;
    justify-content: center;

}

.vector-tl-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background: red; */
    width: 37%;
}

.vector-center-div {
    display: flex;
    margin-top: 41px;
    justify-content: center;
    width: 100%;
}

.vector-center {
    margin-top: 82px;
    width: 170px;
}

.secondlayer-ellip {
    position: relative;
    bottom: 10px;
    display: flex;
    justify-content: center;
}

.secondlayer-ellip-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: red;
}

.secondlayer-ellip-right {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ellip-layer-wrapper {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.soft-phychological-skills {
    background-color: #F5F5F5;
    margin-top: 60px;
    display: flex;
}

.chart-wrapper {
    width: 300px;
    height: 300px;
    display: inline-block;
}

.rating-div {
    width: 90%;
    overflow: clip;
    display: inline-block;
    margin-left: 20px;
    padding-top: 30px
}

.m1-img {
    /* position: absolute;
    top: 62%;
    left: 46% !important;
    width: 100px !important; */
    position: absolute;
    top: 64%;
    left: 42% !important;
    width: 100px !important;
}

.m2-img {
    /* position: absolute;
    top: 43%;
    left: 54% !important;
    width: 64px !important; */
    position: absolute;
    top: 44%;
    left: 49% !important;
    width: 64px !important;
}

.m3-img {
    position: absolute;
    top: 23%;
    left: 50% !important;
    width: 60px !important;
}

.m1-img2 {
    position: absolute;
    top: 62%;
    left: 45% !important;
    width: 95px !important;
}

.m2-img2 {
    position: absolute;
    top: 43%;
    left: 51% !important;
    width: 64px !important;
}

.m3-img2 {
    position: absolute;
    top: 22%;
    left: 52% !important;
    width: 60px !important;
}

.marking-rating-name {
    font-family: 'Manrope' !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.04em;
    color: #000000;
    margin-bottom: 3px;
    text-align: start;
    margin-left: 14px;
}

.marking-rating-span {
    color: #133F7D;
    font-size: 12px;
    font-weight: 600;
}

.soft-skills-wrapper {
    margin-right: 10px;
    width: 100%;
    justify-content: center;
}

.soft-skills-graph-wrapper {
    background-color: #E7EBFB;
    padding: 20px;
    display: flex;
    justify-content: center;
}

.soft-skills-div {
    clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 100% 100%, 0% 100%);
    background-color: #133F7D;
    padding: 10px 10px 10px 14%;
}

.soft-skills-span {
    color: white;
    background-color: #133F7D;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 44px;
    letter-spacing: 0.04em;
}

/* .soft-skills {
    position: relative;
    height: 500px;
    left: 15% !important;
} */
.phychological-skills-wrapper {
    margin-left: 10px;
    /* width: 100%; */
    width: calc(100% - 10px);
}

.phychological-skills-div {
    background-color: #133F7D;
    clip-path: polygon(4% 0%, 16% 100%, 100% 100%, 100% 0%);
    position: relative;
    width: 119%;
    right: 19%;
    display: flex;
    justify-content: flex-end;
}

.psychological-skills-span {
    color: white;
    background-color: #133F7D;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 44px;
    letter-spacing: 0.04em;
    margin: 10px 8% 10px 10px
}

.screenshots-container {
    display: flex;
    justify-content: space-between;

}

.profilepic {
    width: 90%;
}

.rating-button {
    border-radius: 13px;
    margin: 10px !important;
    padding: 20px !important;
}

.rating-arrow {
    border: 1.5px solid #133F7D;
    height: 100%;
    width: 10px;
    border-radius: 10px;
    position: relative;
}

.rating-arrow-tab {
    top: 80%;
    width: 35px;
    height: 25px;
    position: absolute;
}

.rating-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.04em;
}

.mobile-recommendation-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
}

.recommendation-title {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #626262;
}

.radar-graph-labels-main {
    display: flex;
    padding-right: 50px;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
}

.radar-graph-label {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.radar-graph-label-color {
    width: 15px;
    height: 15px;
    border-radius: 2px;
    margin-right: 10px;
}

.overall-rating-center {
    position: absolute;
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 80px;
    color: white;
}

.overall-rating-center b {
    font-size: 30px;
}

@media only screen and (min-width: 900px) {
    .chart-wrapper {
        width: 220px;
        height: 220px;
    }
    .rating-div{
        padding-top: 0px
    }
    .marking-rating-name{
        font-size: 12px;
    }                           
    
    .m1-img {
        position: absolute;
        top: 21%;
        left: 34% !important;
        width: 102px !important;
    }

    .m2-img {
        position: absolute;
        top: 50%;
        left: 47% !important;
        width: 55px !important;
    }

    .m3-img {
        position: absolute;
        top: 77%;
        left: 46% !important;
        width: 60px !important;
    }

    .m1-img2 {
        position: absolute;
        top: 75%;
        left: 35% !important;
        width: 110px !important;
    }

    .m2-img2 {
        position: absolute;
        top: 48%;
        left:51% !important;
        width: 50px !important;
    }

    .m3-img2 {
        position: absolute;
        top: 21%;
        left: 50% !important;
        width: 50px !important;
    }
}

@media only screen and (max-width: 700px) {
    .proxy-chart-img {
        width: 100%;
        justify-content: center;
    }

    .proxy-chart {
        width: 90%;
        justify-content: center;
    }
}

@media only screen and (max-width: 899px) {
    .chart-wrapper {
        width: 300px;
        height: 300px;
    }
    .marking-rating-name{
        font-size: 16px;
    }
    .candidate-skills-wrapper {
        align-content: center !important;
        align-items: center !important;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
        border-radius: 76px !important;
        transform: matrix(-1, 0, 0, 1, 0, 0)
    }

    .candidate-skills {
        margin-top: 6px;
    }

    .overAll-skills-header {
        clip-path: none !important;
        justify-content: center;
        display: flex;
        right: 0% !important;
        width: 100% !important;
    }

    .overAll-skills-span {
        margin-right: 0% !important;
    }

    .overAll-skills {
        width: 100% !important;
        margin-top: 30px !important;
        justify-content: center !important;
    }

    .profilepic {
        width: 95%;
    }

    .vector-center {
        margin-top: 90px;
        width: 185px;
        margin-left: 20px;
    }

    .profile-details {
        top: 76%;
        left: 16%;
    }

    .font-style-profile-span {
        font-size: 1.8vw;
    }

    .font-syle-profile {
        font-size: 1.8vw;
    }

    .tech-details {
        width: 21%;
    }

    .vector-tl-div {
        width: 30%;
    }

    /* .toplayer-ellip-img {
        width: 50%;
    } */

    .font-style-percent {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.04em;
    }

    .soft-skills-div {
        width: 40%;
        margin-bottom: 20px;
    }

    .phychological-skills-wrapper {
        width: 100%;
        margin-left: 0px !important;
        justify-content: flex-end;

    }

    .phychological-skills-div {
        background-color: #133F7D;
        width: 40% !important;
        right: 0% !important;
        margin: 20px;
        left: 58%;
    }

    .marking-2 {
        position: absolute;
        top: 45%;
        left: 81%;
        width: 100%;
    }

    .soft-skills-graph-wrapper {
        display: flex !important;
        justify-content: center !important;
    }

    .desktop-veiw {
        display: none;
    }

    .tech-details {
        top: 70% !important;
        left: 18%;
    }

    .profile-details {
        top: 70% !important;
        left: 62%;
    }

    .m1-img {
        position: absolute;
        top: 26%;
        left: 34% !important;
        width: 120px !important;
    }

    .m2-img {
        position: absolute;
        top: 48%;
        left: 47% !important;
        width: 55px !important;
    }

    .m3-img {
        position: absolute;
        top: 68%;
        left: 49% !important;
        width: 45px !important;
    }

    .m1-img2 {
        position: absolute;
        top: 67%;
        left: 38% !important;
        width: 95px !important;
    }

    .m2-img2 {
        position: absolute;
        top: 46%;
        left: 46% !important;
        width: 55px !important;
    }

    .m3-img2 {
        position: absolute;
        top: 26%;
        left: 45% !important;
        width: 60px !important;
    }

    .rating-div {
        width: 50% !important;
        padding-top: 30px
    }
}



@media only screen and (min-width: 900px) {
    /* .tab-view {
        display: none !important;
    } */
}

@media only screen and (min-width: 600px) {
    .mobile-view-recommendation {
        display: none !important;
    }

    .mobile-view-profile {
        display: none !important;
    }
}

@media only screen and (max-width: 800px) {
    .profile-details {
        right: 6%;
    }

    .vector-center {
        width: 160% !important;
    }

    .phychological-skills-div {
        width: 55% !important;
        left: 42%;
    }

}

@media only screen and (max-width: 600px) {
    .tab-view {
        display: none !important;
    }

    .rating-div {
        width: 90% !important;
    }

    .profilepic {
        width: 100%;
    }

    .font-syle-profile {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #626262;
    }

    .tab-veiw-reccomendation {
        display: none;
    }

    .img-logo {
        margin: 0px !important;
        width: 60% !important;
        margin-top: 0px !important;
    }

    .content-div {
        padding: 0px 0px 0px 46% !important;
        clip-path: polygon(100% 100%, 0% 100%, 15% 0%, 100% 0%);
    }

    .content-div-span {
        margin-left: -55px !important;
    }


    .soft-skills-span {
        font-size: 16px;
    }

    .font-style-header {
        font-size: 16px !important;
    }

    .psychological-skills-span {
        font-size: 16px !important;
    }

    .candidate-skills-heading {
        display: flex !important;
        font-size: 1rem !important;
    }

    .vector-tl-div {
        width: 40%;
    }

    .vector-center {
        margin-top: 90px;
        width: 170%;
    }

    .vector-wrapper {
        bottom: 13px;
    }

    .phychological-skills-div {
        left: 55%;
    }

    .phychological-skills-div {
        left: 39%;
    }

    .phychological-skills-div {
        width: 55% !important;
    }

    .proxy-chart-wrapper {
        border-radius: 20px !important;
        width: 70%;
    }

    .proxy-chart {
        width: 100%;
        justify-content: center;
        display: flex;
    }

    .proxy-title {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
    }

    .proxy-title-wrapper {
        width: 70%;
        display: flex;
    }

    .proxy-rating-span {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        margin-top: 6px;
    }

    .proxy {
        align-items: center;
    }

    .proxy-chart-img {
        width: 40%;
    }

    .recommendation-div {
        height: 16px;
        width: 32%;
    }

    .red {
        left: 6%;
    }

    .arrow-img {
        right: 16%;
    }

    .soft-skills-div {
        padding: 0px 10px 0px 14% !important;
    }

    .psychological-skills-span {
        margin: 0px 8% 0px 10px !important;
    }

    .overAll-skills-header {
        padding-bottom: 0px !important;
        padding-top: 0px !important;
    }

    .btn-g {
        margin-right: 10px !important;
        height: 35px;
        width: 50%;
        font-size: 18px;

    }

    .font-style-profile-span {
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #626262;
    }

    .candidate-overall-skills {
        display: flex;
        margin-top: 40px;
    }

    .info-div {
        display: flex;
        margin: 20px 10px;
        justify-content: start;
    }

    .blank-div {
        height: 110px;
    }

    .soft-skills-graph-wrapper {
        display: flex !important;
        justify-content: center !important;
    }


    .soft-skills {
        right: 0%;
        left: 0% !important;
    }

    .chart-wrapper {
        width: 182px;
        height: 182px;
        display: inline-block;
    }

    .marking-rating-name {
        /* margin-left: 33px !important; */
        font-size: 12px !important;
        line-height: 20px !important;
    }

    .m1-img {
        position: absolute;
        top: 23%;
        left: 31% !important;
        width: 87px !important;
    }

    .m2-img {
        position: absolute;
        top: 48%;
        left: 43% !important;
        width: 52px !important;
    }

    .m3-img {
        position: absolute;
        top: 73%;
        left: 43% !important;
        width: 50px !important;
    }

    .m1-img2 {
        position: absolute;
        top: 70%;
        left: 38% !important;
        width: 75px !important;
    }

    .m2-img2 {
        position: absolute;
        top: 46%;
        left: 47% !important;
        width: 45px !important;
    }

    .m3-img2 {
        position: absolute;
        top: 22%;
        left: 47% !important;
        width: 40px !important;
    }

    .rating-div {
        padding-top: 0px !important;
    }

    .ih-div {
        flex-wrap: wrap !important;
        align-items: flex-start;
        justify-content: flex-start !important;
    }

    .ih-basic-info {
        padding-left: 0 !important;
        width: 100% !important;
    }
}


@media only screen and (max-width: 450px) {
    .chart-wrapper {
        width: 150px;
        height: 150px;
        display: inline-block;
    }
    .m1-img {
        left: 29% !important;
        width: 78px !important;
    }

    .m2-img {
        left: 39% !important;
        width: 45px !important;
    }

    .m3-img {
        left: 39% !important;
        width: 44px !important;
    }

    .m3-img2 {
        top: 21%;
        left: 41% !important;
        width: 44px !important;
    }

    .m2-img2 {
        position: absolute;
        top: 46%;
        left: 41% !important;
        width: 45px !important;
    }
    .m1-img2 {
        position: absolute;
        top: 72%;
        left: 31% !important;
        width: 75px !important;
    }
    .rating-div .marking-rating-name{
        margin-top:15px !important;
    }

    .rating-div .marking-rating-name:last-child{
        margin-top:5px !important;
    }
    
    .candidate-skills{
        width: 250px !important;
        height: 250px !important;
    }
}

@page {
    size: 8.5in 11in;
    /* margin: 27mm 16mm 27mm 16mm; */
}

@media print {

    /* .col-md-4.col-12 {
        flex-basis: 33.33%;
    } */
    .break-stop {
        break-inside: avoid;
    }

    .pagebreak {
        page-break-before: always;
    }

    .information-header {
        flex-wrap: nowrap;
    }

    .ih-result-div {
        width: 50%;
        margin-top: 0px;
    }

    html,
    .interview-feedback-main {
        /* height: 99%;
        width: 1200px; */
    }

    .logo-div {
        margin-top: -50px;
    }

    .logo-div {
        justify-content: start;
    }

    body {
        zoom: 80%;
    }

    #root>div>div:nth-child(1)>div.report-wrapper>div.MuiGrid-root.MuiGrid-container.candidate-overall-skills {
        flex-wrap: nowrap;
    }

    .toplayer-ellip-img {
        width: 100px !important;
    }

    .vector-center {
        width: 60px !important;
        margin-left: 10px !important
    }

    #root>div>div:nth-child(1)>div.report-wrapper>div.MuiGrid-root.MuiGrid-container.candidate-overall-skills {
        margin-top: 100px !important;
    }

    .phychological-skills-wrapper {
        margin-top: 100px !important;
    }

    .screenshot-wrapper {
        max-width: 400px !important;
    }

    /* .donught-graphs {
        width: 200px !important;
    }
  
    .avg_global_main {
        width: 50%;
    }
  
    .avg_global_chart_main {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
    } */

    .chart-wrapper {
        width: 182px !important;
        height: 182px !important;
        display: inline-block;
    }

    .chart-wrapper canvas{
        width: 182px !important;
        height: 182px !important;
    }

    .marking-rating-name {
        /* margin-left: 33px !important; */
        font-size: 12px !important;
        line-height: 20px !important;
    }

    .m1-img {
        position: absolute;
        top: 23%;
        left: 31% !important;
        width: 87px !important;
    }

    .m2-img {
        position: absolute;
        top: 48%;
        left: 43% !important;
        width: 52px !important;
    }

    .m3-img {
        position: absolute;
        top: 73%;
        left: 43% !important;
        width: 50px !important;
    }

    .m1-img2 {
        position: absolute;
        top: 70%;
        left: 38% !important;
        width: 75px !important;
    }

    .m2-img2 {
        position: absolute;
        top: 46%;
        left: 47% !important;
        width: 45px !important;
    }

    .m3-img2 {
        position: absolute;
        top: 22%;
        left: 47% !important;
        width: 40px !important;
    }

    .rating-div {
        padding-top: 0px !important;
    }

    .individual-graph-wrapper{
        flex-basis: 25% !important;
    }

    .vector-center{
        margin-left: 25px !important;
        width: 80px !important;
    }
}